var Cameraland = Cameraland || {};

Cameraland.Menu = class {
    constructor(options) {
        this.menuOpen = this.menuOpen.bind(this);
        this.menuClose = this.menuClose.bind(this);
        this.hookEvents = this.hookEvents.bind(this);
        this.setMobile = this.setMobile.bind(this);
        this.setDesktop = this.setDesktop.bind(this);

        this.options = $j.extend({}, {
            closeBtnSelector: '.close-button',
            menuBtnSelector: '.nav-button',
            menuSelector: '#nav',
            menuListSelector: '#nav .primary-nav',
            menuItemSelector: '.toggle-btn',
            menuHoverSelector: 'li.level-top',
            closeSubmenuSelector: '.close-submenu',
            submenuSelector: '.parent',
        }, options);

        enquire.register('screen and (max-width:' + bp.medium + 'px)', {
            match: this.setMobile,
            unmatch: this.setDesktop
        });
    }

    menuClose() {
        const menu = this.options.menuSelector;
        const menuBtnSelector = this.options.menuBtnSelector;

        $j(menuBtnSelector).removeClass('active');
        $j(menu).removeClass('active');
        $j(this.options.submenuSelector).removeClass('open');
    }

    menuOpen() {
        const menu = this.options.menuSelector;
        const menuBtnSelector = this.options.menuBtnSelector;

        $j(menuBtnSelector).addClass('active');
        $j(menu).addClass('active');

        $j(menu).on('click', menuBtnSelector, (event) => {
            event.preventDefault();
            this.menuClose();
        });
    }

    hookEvents() {
        const menu = this.options.menuSelector;
        const menuBtnSelector = this.options.menuBtnSelector;
        const closeBtnSelector = this.options.closeBtnSelector;

        $j(menu).on('click', this.options.menuItemSelector, (event) => {
            event.preventDefault();
            const menuItem = event.currentTarget;
            const menuItemParent = $j(menuItem).parent();

            menuItemParent.toggleClass('open');
        });

        $j(menu).on('click', menuBtnSelector, (event) => {
            event.preventDefault();
            this.menuOpen();
        });

        $j(menu).on('click', closeBtnSelector, (event) => {
            event.preventDefault();
            this.menuClose();
        });

        $j('body').on('swipeleft', menu, () => {
            this.menuClose();
        });
    }

    setMobile() {
        this.hookEvents();
    }

    setDesktop() {

    }

};

$j(function () {
    new Cameraland.Menu();
});